











import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import { GraphsDTO } from '@/services/dashboard/types'
import Vue from 'vue'

use([CanvasRenderer, PieChart, BarChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent])

export default Vue.extend({
  name: 'HomeGraphs',
  components: {
    VChart,
  },
  props: {
    graphData: {
      type: Array as () => GraphsDTO[],
      default: [],
    },
  },
  computed: {
    datas(): any { // eslint-disable-line
      return this.graphData
    },
    option(): any { // eslint-disable-line
      return {
        title: {
          text: this.$tc('boughtSoldEnergy'),
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: [
            this.datas[0] ? this.$t(this.datas[0].listItems[0].value.p_type) : '',
            this.datas[0] ? this.$t(this.datas[0].listItems[1].value.p_type) : '',
          ],
        },
        series: [
          {
            name: 'Traffic Sources',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              {
                value: this.datas[0] ? this.datas[0].listItems[0].value.sum : 0,
                name: this.datas[0] ? this.$t(this.datas[0].listItems[0].value.p_type) : '',
              },
              {
                value: this.datas[0] ? this.datas[0].listItems[1].value.sum : 0,
                name: this.datas[0] ? this.$t(this.datas[0].listItems[1].value.p_type) : '',
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },

    option2(): any { // eslint-disable-line
      return {
        title: {
          text: this.$tc('purchasesSellsPerWeek'),
          left: 'center',
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          data: [this.$tc('buy'), this.$tc('sell')],
        },
        tooltip: {},
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [
          {
            name: this.$tc('buy'),
            type: 'bar',
            data: [20, 30, 40, 45, 35],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
          {
            name: this.$tc('sell'),
            type: 'bar',
            data: [60, 70, 80, 85, 75],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },
  },
})
