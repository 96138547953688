var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('h2',{staticClass:"mt-5 mb-2"},[_vm._v(_vm._s(_vm.$tc('home')))]),_c('v-divider'),_c('home-graphs',{attrs:{"graph-data":_vm.graphs}}),_c('home-stats',{attrs:{"statistics":_vm.stats}}),_c('v-row',{attrs:{"no-gutters":"","align":"end"}},[_c('h2',{staticClass:"mb-2 mr-5"},[_vm._v(_vm._s(_vm.$tc('activeTenders')))]),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":"","tile":"","color":"primary","group":""}},[_c('v-btn',{staticClass:"mb-0 pr-1",attrs:{"plain":"","ripple":false},on:{"click":function($event){return _vm.fetchActiveTenders('buy')}}},[_vm._v(_vm._s(_vm.$tc('buy')))]),_c('v-btn',{staticClass:"mb-0 pl-1",attrs:{"plain":"","ripple":false},on:{"click":function($event){return _vm.fetchActiveTenders('sell')}}},[_vm._v(_vm._s(_vm.$tc('sell')))])],1),(_vm.isAdmin || _vm.isOperationDirector)?_c('v-divider',{staticClass:"my-3 mx-1",attrs:{"vertical":""}}):_vm._e(),(_vm.isAdmin || _vm.isOperationDirector)?_c('v-btn',{staticClass:"ma-auto text-capitalize font-weight-bold",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.goToAllTenders()}}},[_vm._v(" "+_vm._s(_vm.$tc('viewAll'))+" ")]):_vm._e()],1),_c('v-divider'),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.items.value,"loading":_vm.loading,"options":_vm.pagination.value,"server-items-length":_vm.totalRecords,"fixed-header":"","outlined":"","hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.pagination, "value", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(((item.publishTime) + " " + (_vm.formatDate(item.publishDate)))))]),_c('td',[_vm._v(_vm._s(((item.endTime) + " " + (_vm.formatDate(item.endDate)))))]),_c('td',[_vm._v(_vm._s(_vm.formatEvaluationTime(item.offerValidityHours, item.offerValidityMinutes)))]),_c('td',[_c('tender-status',{attrs:{"status":item.status}})],1),_c('td',[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(item.status === _vm.ProcurementStatus.DRAFT && !_vm.isClient)?_c('publish-button',{staticClass:"mx-1",attrs:{"id":item.id},on:{"published":_vm.fetchData}}):_vm._e(),(
                item.status !== _vm.ProcurementStatus.DRAFT &&
                item.status !== _vm.ProcurementStatus.EVALUATING_OFFERS &&
                !_vm.isClient
              )?_c('show-button',{staticClass:"mx-1",attrs:{"to":{
                name: 'ClosedTenderDetails',
                params: { id: item.id },
                query: { status: item.status, redirectedFrom: 'Home' },
              }}}):_vm._e(),(item.status === _vm.ProcurementStatus.DRAFT && !_vm.isClient)?_c('edit-button',{staticClass:"mx-1",attrs:{"to":{ name: 'EditTender', params: { id: item.id } }}}):_vm._e(),(item.status === _vm.ProcurementStatus.DRAFT && !_vm.isClient)?_c('delete-button',{attrs:{"id":item.id,"label":_vm.$tc('deleteTender'),"text":_vm.$tc('deleteTenderText'),"service":_vm.TenderService},on:{"deleted":_vm.fetchData}}):_vm._e()],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }